import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-container", {
    staticClass: "layout-cont"
  }, [_c("el-container", {
    class: [_vm.isSider ? "openside" : "hideside", _vm.isMobile ? "mobile" : ""]
  }, [_c("el-row", {
    class: [_vm.isShadowBg ? "shadowBg" : ""],
    nativeOn: {
      click: function click($event) {
        return _vm.changeShadow();
      }
    }
  }), _c("el-aside", {
    staticClass: "main-cont main-left"
  }, [_c("div", {
    staticClass: "tilte"
  }, [_c("img", {
    staticClass: "logoimg",
    attrs: {
      alt: "",
      src: require("@/assets/nav_logo.png")
    }
  }), _vm.isSider ? _c("h2", {
    staticClass: "tit-text"
  }, [_vm._v("Zenlayer DNS")]) : _vm._e()]), _c("div", {
    staticClass: "div-aside",
    on: {
      mouseenter: _vm.showAside,
      mouseleave: _vm.hideAside
    }
  }, [_c("Aside", {
    staticClass: "aside"
  })], 1)]), _c("el-main", {
    staticClass: "main-cont main-right"
  }, [_c("transition", {
    attrs: {
      duration: {
        enter: 800,
        leave: 100
      },
      mode: "out-in",
      name: "el-fade-in-linear"
    }
  }, [_c("div", {
    staticClass: "topfix",
    style: {
      width: "calc(100% - ".concat(_vm.isMobile ? "0px" : _vm.isCollapse ? "54px" : "220px", ")")
    }
  }, [_c("el-row", {
    staticStyle: {
      background: "#909399"
    }
  }, [_c("el-header", {
    staticClass: "header-cont"
  }, [_c("el-col", {
    attrs: {
      xs: 10,
      lg: 15,
      md: 13,
      sm: 8,
      xl: 15
    }
  }, [_c("el-breadcrumb", {
    staticClass: "breadcrumb",
    attrs: {
      "separator-class": "el-icon-arrow-right"
    }
  }, _vm._l(_vm.matched.slice(1, _vm.matched.length), function (item) {
    return _c("el-breadcrumb-item", {
      key: item.path
    }, [_vm._v(_vm._s(_vm.menusTitle(item.meta.title)))]);
  }), 1)], 1), _c("el-col", {
    attrs: {
      xs: 14,
      lg: 9,
      md: 11,
      sm: 16,
      xl: 9
    }
  }, [_c("div", {
    staticClass: "div-user-setting"
  }, [_c("el-menu", {
    staticClass: "el-menu-demo",
    attrs: {
      mode: "horizontal",
      "menu-trigger": "hover",
      "active-text-color": "#909399"
    }
  }, [_c("el-submenu", {
    attrs: {
      index: "2"
    }
  }, [_c("template", {
    slot: "title"
  }, [_c("div", {
    staticClass: "circle"
  }, [_vm._v(_vm._s(_vm.teamName))]), _c("span", {
    staticStyle: {
      "margin-left": "5px"
    },
    attrs: {
      effect: "plain",
      size: "mini"
    }
  }, [_vm._v(_vm._s(_vm.currTeam.name))])]), _c("el-menu-item", {
    attrs: {
      index: "2-1"
    },
    nativeOn: {
      click: function click($event) {
        return _vm.openMemberPage.apply(null, arguments);
      }
    }
  }, [_c("i", {
    staticClass: "el-icon-s-grid"
  }), _vm._v(_vm._s(_vm.$t("layout.member")) + " ")]), _c("el-submenu", {
    attrs: {
      index: "2-2"
    }
  }, [_c("template", {
    slot: "title"
  }, [_c("i", {
    staticClass: "el-icon-refresh"
  }), _vm._v(_vm._s(_vm.$t("layout.team")))]), _vm._l(_vm.teams, function (item, index) {
    return _c("el-menu-item", {
      key: item.teamId,
      attrs: {
        index: "1-2-" + index
      },
      nativeOn: {
        click: function click($event) {
          return _vm.changeTeams(item.teamId, item.teamName, item.authId);
        }
      }
    }, [_c("div", {
      staticClass: "team-item"
    }, [_c("div", {
      staticClass: "team-item-title"
    }, [_vm._v(_vm._s(item.teamName))]), _c("div", {
      staticClass: "team-item-active"
    }, [_vm.currTeam.id == item.teamId ? _c("i", {
      staticClass: "el-icon-check"
    }) : _vm._e()])])]);
  })], 2)], 2), _c("el-menu-item", {
    attrs: {
      index: "3"
    }
  }, [_c("el-dropdown", [_c("span", {
    staticClass: "el-dropdown-link"
  }, [_c("span", [_vm._v("Hi, " + _vm._s(_vm.userInfo.nickName))]), _c("i", {
    staticClass: "el-icon-setting"
  })]), _c("el-dropdown-menu", {
    staticClass: "dropdown-group",
    attrs: {
      slot: "dropdown"
    },
    slot: "dropdown"
  }, [_c("el-dropdown-item", {
    attrs: {
      icon: "el-icon-s-custom"
    },
    nativeOn: {
      click: function click($event) {
        return _vm.toPerson.apply(null, arguments);
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("layout.setting")))]), _c("el-dropdown-item", {
    attrs: {
      divided: "",
      icon: "el-icon-table-lamp"
    },
    nativeOn: {
      click: function click($event) {
        return _vm.LoginOut.apply(null, arguments);
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("layout.logout")))])], 1)], 1)], 1)], 1)], 1)])], 1)], 1)], 1)]), _c("transition", {
    attrs: {
      mode: "out-in",
      name: "el-fade-in-linear"
    }
  }, [_c("keep-alive", [_vm.$route.meta.keepAlive && _vm.reloadFlag ? _c("router-view", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loadingFlag,
      expression: "loadingFlag"
    }],
    staticClass: "admin-box",
    attrs: {
      "element-loading-text": _vm.$t("layout.loading")
    }
  }) : _vm._e()], 1)], 1), _c("transition", {
    attrs: {
      mode: "out-in",
      name: "el-fade-in-linear"
    }
  }, [!_vm.$route.meta.keepAlive && _vm.reloadFlag ? _c("router-view", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loadingFlag,
      expression: "loadingFlag"
    }],
    staticClass: "admin-box",
    attrs: {
      "element-loading-text": _vm.$t("layout.loading")
    }
  }) : _vm._e()], 1), _c("BottomInfo")], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };